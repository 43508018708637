import React from "react";
import styled from "styled-components";
import { AIButton } from "@view/component/button";
import useResize from "@view/hooks/useResize";
import Button from "@view/widget/button";
import Icon from "@icon/index";
interface FaceFitPromotionProps {
  clickPromotion: () => void;
  clickRecentConsultations: () => void;
}

const FaceFitPromotion: React.FC<FaceFitPromotionProps> = ({ clickPromotion, clickRecentConsultations }) => {
  const { width } = useResize();
  return (
    <Layout>
      <FaceFitPromotionLayout>
        {width > 768 ? (
          <LabelLayout>
            <Text15px>입점프로모션 진행중</Text15px>
          </LabelLayout>
        ) : null}

        <CenterLayout>
          {width > 768 ? (
            <Title>
              닥터홀인원의 <TitleEM>페이스핏</TitleEM>으로 고객에게 최적의 얼굴을 찾아주세요.
              <Star src="/image/home/face-fit-promotion/star.png" />
            </Title>
          ) : (
            <Title>
              Face Fit
              <Star src="/image/home/face-fit-promotion/star.png" />
            </Title>
          )}

          <Information>
            {width > 768
              ? "AI 성형분석 페이스핏으로 최적의 얼굴을 찾아드립니다."
              : "모바일에서 고객사진을 촬영&등록하고\nPC에서 바로 상담을 진행하세요."}
          </Information>
          {width > 768 ? (
            <AIButton
              label="Face Fit START"
              width={229}
              height={48}
              fontSize={20}
              fontWeight={500}
              type={"WHITE"}
              click={clickPromotion}
            />
          ) : (
            <ButtonsLayout>
              <Button.ComponentButton
                component={
                  <ButtonLayout>
                    <Icon.FilledCamera
                      colorCode="#033632"
                      xlarge
                    />
                    <ButtonLabel>{"Face Fit\n사진촬영"}</ButtonLabel>
                  </ButtonLayout>
                }
                sx={{ width: "100%", borderRadius: "8px" }}
                onClick={clickPromotion}
              />
              <Button.ComponentButton
                component={
                  <ButtonLayout>
                    <Icon.FilledRecording
                      colorCode="#F3584C"
                      xlarge
                    />
                    <ButtonLabel>{"상담노트\n대기화면"}</ButtonLabel>
                  </ButtonLayout>
                }
                sx={{ width: "100%", borderRadius: "8px" }}
                onClick={clickRecentConsultations}
              />
            </ButtonsLayout>
          )}
        </CenterLayout>
      </FaceFitPromotionLayout>
    </Layout>
  );
};

export default FaceFitPromotion;

const Layout = styled.div`
  @media screen and (max-width: 768px) {
    width: calc(100vw - 24px);
    margin-bottom: 36px;
  }
`;

const FaceFitPromotionLayout = styled.div`
  position: relative;
  width: 100%;
  height: 237px;
  border-radius: 16px;
  background: linear-gradient(86deg, rgba(28, 92, 86, 0) 34.83%, #1c5c56 97.62%),
    linear-gradient(276deg, rgba(0, 0, 0, 0.28) 27.68%, #36766d 98.49%), #000;
  padding: 14px 18px;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 360px;
  }
`;

const LabelLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const Text15px = styled.div`
  color: #f3f4f0;
  font-family: SUIT;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 19.5px */
`;

const CenterLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 14px;
`;

const Title = styled.div`
  position: relative;
  color: #fff;
  font-family: SUIT;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 5px;

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const TitleEM = styled.span`
  color: #ddff0b;
`;

const Information = styled.div`
  color: #f3f4f0;
  font-family: SUIT;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 29px;

  @media screen and (max-width: 768px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const Star = styled.img`
  position: absolute;
  width: 27px;
  height: 27px;
  top: -10px;
  right: -9px;

  @media screen and (max-width: 768px) {
    position: relative;
    right: 0px;
    width: 20px;
    height: 20px;
  }
`;

const ButtonsLayout = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

const ButtonLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  background: linear-gradient(180deg, #cecece 10.21%, #fff 50.05%);
`;

const ButtonLabel = styled.div`
  white-space: pre-wrap;
  text-align: center;
  font-size: 16px;
  text-shadow: 0px 4px 15px rgba(160, 255, 238, 0.25);
  color: #636363;
  line-height: 130%;
`;
