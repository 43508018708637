import React from "react";
import styled from "styled-components";
import Slider, { Settings } from "react-slick";

const BottomBanner: React.FC = () => {
  const settings: Settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
  };

  return (
    <BottomBannerLayout>
      <Slider {...settings}></Slider>
    </BottomBannerLayout>
  );
};

export default BottomBanner;

const BottomBannerLayout = styled.div`
  width: 100%;
  height: 180px;
`;
