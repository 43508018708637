import Typography from "@view/widget/typography";
import styled from "styled-components";
import { HomeInfo } from "../HomeView";
import colors from "@view/styles/colors";
import { DeviceType } from "@view/hooks/useDeviceDetect";

interface Props {
  infos: HomeInfo[];
  deviceType: DeviceType;
}

const InfoCard = (props: Props) => {
  const { infos, deviceType } = props;

  const calcWidth = `${100 / infos.length}%`;

  return (
    <InfoWrapper deviceType={deviceType}>
      {infos.map((e) => (
        <InfoContainer
          key={e.title}
          onClick={e.onClick}
          deviceType={deviceType}
          width={deviceType === "tablet" ? calcWidth : undefined}
        >
          {e.icon}
          <SpaceContainer />
          <Typography.B1
            bold
            value={e.title}
          />
          <SpaceContainer />
          <Typography.B2 value={e.content} />
        </InfoContainer>
      ))}
      <QRInfoLayout>
        <QRImage src={"/image/dr-wo-qr.png"} />
        <QRInfoMessageLayout>
          <span className="qr-title">Mobile Dr whole in one</span>
          <br />
          <span className="qr-info">{"모바일에서 상담고객 촬영 후 PC에서\n상담을 이어가세요."}</span>
        </QRInfoMessageLayout>
      </QRInfoLayout>
    </InfoWrapper>
  );
};

export default InfoCard;

const InfoWrapper = styled.div<{ deviceType: DeviceType }>`
  display: flex;
  flex-direction: ${(props) => (props.deviceType === "tablet" ? "row" : "column")};
  justify-content: ${(props) => (props.deviceType === "tablet" ? "space-between" : "center")};
  flex-wrap: wrap;
  gap: 8px;
`;

const InfoContainer = styled.div<{ deviceType: DeviceType; width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => (props.deviceType === "desktop" ? "328px" : "100%")};
  height: 54px;
  border-radius: 12px;
  padding: 8px 16px;
  border: 1px solid ${colors.neutralLineDefault};
  min-width: 300px;
  flex: 1;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    max-width: calc(100vw - 24px);
  }
`;

const SpaceContainer = styled.div`
  width: 19px;
`;

const QRInfoLayout = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #eceef2;
  padding-inline: 8px;
  height: 87px;
`;

const QRImage = styled.img`
  width: 72px;
  height: 72px;
  max-width: 72px;
  max-height: 72px;
`;

const QRInfoMessageLayout = styled.div`
  margin-left: 6px;
  .qr-title {
    font-size: 14px;
    font-weight: 600;
    color: #14443f;
  }
  .qr-info {
    font-size: 12px;
    word-break: keep-all;
    white-space: pre-wrap;
    color: #8d9797;
  }
`;
