import { Pagination, PaginationProps } from "@view/widget/listTable";
import styled from "styled-components";
import Typography from "@view/widget/typography";
import { ViewData } from "@view-data/index";
import { parseDateString } from "@view/utils/common";
import colors from "@view/styles/colors";
import InfoCard from "./InfoCard";
import { HomeInfo } from "../HomeView";
import Icon from "@icon/index";
import { useState } from "react";
import { DeviceType } from "@view/hooks/useDeviceDetect";

interface Props {
  notices: ViewData.Notice[];
  totalCount: number;
  pagination?: Omit<PaginationProps, "totalCount"> | undefined;
  deviceType: DeviceType;
  homeInfo: HomeInfo[];
}

const Notices: React.FC<Props> = (props) => {
  const { notices, totalCount, pagination, deviceType, homeInfo } = props;
  const visibleObject = {
    visible: false,
  };

  const [detailVisible, setDetailVisible] = useState<Visible[]>(
    Array.from({ length: 10 }).map(() => ({
      ...visibleObject,
    })),
  );

  const onClickKakaoChannel = () => {
    window.open("https://pf.kakao.com/_ZxfHtC", "_blank");
  };

  const handleVisible = (index: number) => {
    let newJson = { ...detailVisible };
    newJson[index].visible = !newJson[index].visible;
    setDetailVisible(newJson);
  };

  const extractTextPattern = /(<([^>]+)>)/gi;

  const ContentList = notices?.map((item, index) => (
    <NoticeTableCellStyled key={item.title + index}>
      <NoticeRow onClick={() => handleVisible(index)}>
        <NoticeContents>
          <IndexBox>
            <Typography.B1 value={totalCount - index - (pagination!.currentPage - 1) * 10} />
          </IndexBox>
          {detailVisible[index].visible ? (
            <Icon.ArrowClose
              color="neutralBlack"
              xsmall
            />
          ) : (
            <Icon.ArrowOpen
              color="neutralBlack"
              xsmall
            />
          )}
          <NoticeTitleWrapper isDesktop={deviceType === "desktop"}>{item.title}</NoticeTitleWrapper>
        </NoticeContents>
        <Typography.B1
          value={parseDateString(item.created, "YYYY.MM.DD")}
          color={colors.neutralGray600}
        />
      </NoticeRow>
      {detailVisible[index].visible && (
        <NoticeDetailContainer>
          <NoticeHtmlSection dangerouslySetInnerHTML={{ __html: item.context }} />
        </NoticeDetailContainer>
      )}
    </NoticeTableCellStyled>
  ));

  return (
    <NoticeContainer>
      <Typography.T1
        bold
        value="공지사항"
      />
      <NoticeElementWrapper isDesktop={deviceType === "desktop"}>
        <NoticeWrapper isDesktop={deviceType === "desktop"}>
          {notices?.length > 0 ? (
            ContentList
          ) : (
            <EmptyContainer>
              <Typography.B1
                value="등록된 게시물이 없습니다"
                color={colors.neutralGray600}
              />
            </EmptyContainer>
          )}
        </NoticeWrapper>
        {deviceType !== "desktop" ? (
          <InfoWrapper
            deviceType={deviceType}
            hasPage={totalCount > 0}
          >
            {homeInfo.length > 0 && (
              <InfoCard
                deviceType={deviceType}
                infos={homeInfo}
              />
            )}
          </InfoWrapper>
        ) : null}
      </NoticeElementWrapper>
    </NoticeContainer>
  );
};

export default Notices;

const PaginationLayout = styled.div`
  padding-top: 16px;
`;

const NoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NoticeElementWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
  height: 100%;
  gap: 32px;
`;

const NoticeWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: ${(props) => (props.isDesktop ? "824px" : "100%")}; */
  /* ${(props) => props.isDesktop && `min-width: 824px;`} */
`;

const NoticeContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const NoticeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  border-bottom: 1px solid ${colors.neutralLineDefault};
  padding: 0 18px;
  gap: 10px;
  cursor: pointer;
`;

const NoticeDetailContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafdff;
  padding: 20px 30px;
`;

const NoticeHtmlSection = styled.div`
  font-size: 12px;
  color: #777777;
  white-space: pre-wrap;
`;

const NoticeTitleWrapper = styled.div<{ isDesktop: boolean }>`
  font-size: 16px;
  ${(props) =>
    !props.isDesktop &&
    `
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`}
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoWrapper = styled.div<{ hasPage: boolean; deviceType: DeviceType }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.deviceType !== "desktop" ? (props.deviceType === "tablet" ? "space-between" : "flex-end") : "flex-end"};
  flex-wrap: wrap;
  width: 100%;
  ${(props) => props.hasPage && `padding-bottom: 68px;`}
`;

const NoticeTableCellStyled = styled.div`
  padding: 4px;
  text-align: left;
`;

const IndexBox = styled.div`
  width: 37px;
`;

interface Visible {
  visible: boolean;
}
