import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useDeviceDetect from "@view/hooks/useDeviceDetect";
import colors from "@view/styles/colors";
import { ViewData } from "@view-data/index";
import Notices, { InfoWrapper } from "./components/Notices";
import { PaginationProps } from "@view/widget/listTable";
import { useContainer } from "inversify-react";
import { Subscription } from "rxjs";
import { NextRouter, useRouter } from "next/router";
import InfoCard from "./components/InfoCard";
import Image from "next/image";
import HomeMail from "@image/home-mail.png";
import HomeKakao from "@image/home-kakao.png";
import { useContextState } from "@view/context";
import { HomeViewModel } from "@view-model/model/HomeViewModel";
import * as HomeViewComponents from "./components";
import useResize from "@view/hooks/useResize";

export interface HomeInfo {
  title: string;
  content: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

export interface CommentViewProps {
  count?: number;
  text?: string;
  circleColor: string;
}

export interface WaitListForDoctor {
  id: number;
  profile?: string;
  head: boolean;
  doctor: string;
  count: number;
}

const HomeView = (props: any) => {
  const userInfo = props;

  const router: NextRouter = useRouter();
  const { width } = useResize();

  const [notices, setNotices] = useState<ViewData.Notice[]>([]);
  const { state, setState } = useContextState();
  const [pagination, setPagination] = useState<PaginationProps>();
  const [doctors, setDoctors] = useState<ViewData.Doctor[]>([]);
  const [doctorsPagination, setDoctorsPagination] = useState<PaginationProps>();
  const [waitingConfirms, setWaitingConfirms] = useState<ViewData.PatientConfirm[]>([]);
  const [homeWidth, setHomeWidth] = useState<number | null>(null);
  const [comments, setComments] = useState<CommentViewProps[]>([
    {
      count: 0,
      text: "시술 승인 대기",
      circleColor: colors.taskCounselNew,
    },
    {
      count: 0,
      text: "요청된 상담",
      circleColor: colors.taskCounselUnknown,
    },
    {
      count: 0,
      text: "답변된 상담",
      circleColor: colors.taskCounselDone,
    },
    {
      count: 0,
      text: "미답변된 상담",
      circleColor: colors.semanticError,
    },
  ]);

  const homeContainerRef = useRef<HTMLDivElement>(null);

  const deviceType = useDeviceDetect(true);

  const id = router.query.id ? Number(router.query.id) : userInfo ? userInfo.id : state.id;
  const accountType = router.query.accountType ? router.query.accountType.toString() : userInfo.accountType;

  const vm = useContainer((container) => {
    const factory = container.get<(id: number, accountType: string) => HomeViewModel>("HomeViewModel");
    return factory(id, accountType);
  });

  const handlePage = (page: number) => {
    vm.input.onClickPagination(page);
  };

  const validate = () => {
    setHomeWidth(window.innerWidth);
  };

  useEffect(() => {
    const sessionUserInfo = sessionStorage.getItem("sessionUserInfo");

    if (sessionUserInfo) {
      setState({
        id: JSON.parse(sessionUserInfo!).id,
        accountType: JSON.parse(sessionUserInfo!).accountType,
      });
    }

    const subscriptions: Subscription[] = [
      vm.output.displayNotices.subscribe((output: ViewData.NoticeListDisplayOutput) => {
        setNotices(output.notices);
        const paginationObject: PaginationProps = {
          limit: output.pagination?.limit,
          currentPage: output.pagination?.page,
          onPageChange: handlePage,
          totalCount: output.pagination?.count,
        };
        setPagination(paginationObject);
      }),
      vm.output.displayDoctors.subscribe((output: ViewData.DoctorListDisplayOutput) => {
        setDoctors((prevItems) => {
          return prevItems ? prevItems.concat(output.items) : output.items;
        });
        const paginationObject: PaginationProps = {
          limit: vm.data.limit ? vm.data.limit : 20,
          currentPage: output.page,
          onPageChange: handlePage,
          totalCount: output.size,
        };
        setDoctorsPagination(paginationObject);
      }),
      vm.output.displayComments.subscribe((output: ViewData.Comment[]) => {
        comments[1].count = output.length;
        const answeredCnt = output.filter((d) => d.confirmedStatus === "ANSWERED").length;
        comments[2].count = answeredCnt;
        comments[3].count = output.length - answeredCnt;
        setComments([...comments]);
      }),
      vm.output.displayWaitingConfirms.subscribe((output: ViewData.PatientConfirm[]) => {
        comments[0].count = comments[0].count ? comments[0].count + output.length : output.length;
        setComments([...comments]);
        setWaitingConfirms(output);
      }),
      vm.output.displayPartnerFeedback.subscribe((output: ViewData.PartnerFeedback) => {
        comments[0].count = output.pendingConfirmPatientCnt;
        comments[1].count = output.requestedCommentCnt;
        comments[2].count = output.answeredCommentCnt;
        comments[3].count = output.pendingCommentCnt;
        setComments([...comments]);
      }),
      vm.route.toFaceFit.subscribe(({ isMobile }) => {
        router.push({
          pathname: "/consulting/assistant",
          query: { service: "before-and-after", modal: "photo-uploader", mode: isMobile ? "fitting" : "standby" },
        });
      }),
      vm.route.toRecentConsultations.subscribe(() => {
        router.push({ pathname: "/face-fit/consultations/recent" });
      }),
    ];

    validate();

    window.addEventListener("resize", validate);

    return () => {
      window.removeEventListener("resize", validate);
      subscriptions.forEach((s) => s.unsubscribe());
    };
  }, []);

  useEffect(() => {
    if (doctors && doctorsPagination && accountType === "C") {
      // if (doctors.length !== doctorsPagination.totalCount) {
      //   vm.input.onLoadDoctors(doctorsPagination.currentPage + 1);
      // }
    }
  }, [doctors.length, doctorsPagination]);

  useEffect(() => {
    if (homeContainerRef.current?.clientWidth) setHomeWidth(homeContainerRef.current?.clientWidth);
  }, [homeContainerRef.current?.clientWidth]);

  const onClickKakaoChannel = () => {
    window.open("https://pf.kakao.com/_ZxfHtC", "_blank");
  };

  const homeInfo: HomeInfo[] = [
    {
      title: "문의메일",
      content: "afoter@icaremind.com",
      icon: (
        <Image
          src={HomeMail.src}
          alt="home-kakao"
          width={38}
          height={38}
        />
      ),
    },
    {
      title: "카카오채널",
      content: "에포터",
      icon: (
        <Image
          src={HomeKakao.src}
          alt="home-kakao"
          width={38}
          height={38}
        />
      ),
      onClick: onClickKakaoChannel,
    },
    {
      title: "이용가이드",
      content: "",
      icon: (
        <Image
          src={"/image/guide-document.png"}
          alt="home-kakao"
          width={38}
          height={38}
        />
      ),
      onClick: () => {
        window.open("https://drwholeinone.notion.site/ac50839f5fc4439a89f94854f38e6cdb");
      },
    },
  ];

  return (
    <HomeContainer
      ref={homeContainerRef}
      width={homeWidth}
    >
      <HomeWrapper>
        <HomeViewComponents.TopBanner.FaceFitPromotion
          clickPromotion={() => vm.route.toFaceFit.next({ isMobile: width < 769 })}
          clickRecentConsultations={() => {
            vm.route.toRecentConsultations.next();
          }}
        />
        <TotalConsultation isDesktop={deviceType === "desktop"}>
          <ConsultationSection justifyContent={deviceType === "desktop" ? "start" : "space-between"}>
            {deviceType === "desktop" ? (
              <Notices
                notices={notices}
                totalCount={pagination?.totalCount ? pagination.totalCount : notices?.length}
                pagination={pagination}
                deviceType={deviceType}
                homeInfo={homeInfo}
              />
            ) : null}
          </ConsultationSection>
          <InfoSection>
            {deviceType === "desktop" && (
              <InfoWrapper
                deviceType={deviceType}
                hasPage={notices?.length > 0}
              >
                {homeInfo.length > 0 && (
                  <InfoCard
                    deviceType={deviceType}
                    infos={homeInfo}
                  />
                )}
              </InfoWrapper>
            )}
          </InfoSection>
        </TotalConsultation>
        {deviceType !== "desktop" && (
          <Notices
            notices={notices}
            totalCount={pagination?.totalCount ? pagination.totalCount : notices.length}
            pagination={pagination}
            deviceType={deviceType}
            homeInfo={homeInfo}
          />
        )}
      </HomeWrapper>
    </HomeContainer>
  );
};

export default HomeView;

const HomeContainer = styled.div<{ width: number | null }>`
  display: flex;
  justify-content: ${(props) => (props.width && props.width < 1168 ? "flex-start" : "center")};
  height: 100%;
`;

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
  height: calc(100% - 60px);
  width: 100%;
  max-width: 1240px;

  @media screen and (max-width: 768px) {
    padding-left: 12px;
    height: fit-content;
  }
`;

const HomeBannerStyled = styled.img<{ isDesktop?: boolean }>`
  width: ${(props) => (props.isDesktop ? "1168px" : "100%")};
  margin: 24px 0 34px 0;
  height: 100%;
  border-radius: 12px;
  object-fit: contain;
`;

const ConsultationSection = styled.div<{ justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-between")};
  gap: 8px;
  width: 100%;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TotalConsultation = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
  gap: ${(props) => (props.isDesktop ? "16px" : "32px")};
  height: 100%;
  margin-block: 36px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const DoctorCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
